import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Earn from './Earn.js';
import Footer from './Footer.js';
import Friends from './Friends.js';
import Airdrop from './Airdrop.js';
import Profile from './Profile.js';
import Settings from './Settings.js';
import Header from './Header.js';
import Task from './Task.js';
import Spin from './Spin.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import translations from './utils/translations';
import { API_URL } from './utils/config';

const tg = window.Telegram.WebApp;


const App = () => {
    const [user, setUser] = useState(null);
    const [sound, setSound] = useState(true);
    const [taskNum, setTaskNum] = useState(0);
    const [vibration, setVibration] = useState(true);
    const [coins, setCoins] = useState(0);
    const [level, setLevel] = useState(0);
    const [progress, setProgress] = useState(0);
    const [incomeHour, setIncomeHour] = useState(0);
    const [incomeTap, setIncomeTap] = useState(1);
    const [energy, setEnergy] = useState(500);
    const [maxEnergy, setMaxEnergy] = useState(500);
    const [remainingTime, setRemainingTime] = useState(0);
    const [spins, setSpins] = useState(0);
    const [error, setError] = useState(null);
    const [language, setLanguage] = useState('en');
    const t = (key) => translations[language][key] || key;

    useEffect(() => {
        tg.ready();
        console.log('Telegram Web App ready');
        const userData = tg.initDataUnsafe.user;
        // const userData = {
        //     id: 1074699355,
        //     username: 'davbaghdasaryan',
        //     first_name: 'Dav',
        //     photo_url: 'https://t.me/i/userpic/320/Yg0ESF1zRuff9eKMie3860DCrFtwATVKh-SA-bZ75BI.svg'
        // };
        setUser(userData);

        if (userData) {
            saveUser(userData);
            fetchTasksNum(userData.id);
            fetchUserStats(userData.id);
            fetchUserEnergy(userData.id);
            fetchSpinStatus(userData.id);

            const intervalId = setInterval(() => {
                fetchUserStats(userData.id);
                fetchUserEnergy(userData.id);
                fetchSpinStatus(userData.id);
            }, 1000); // 5000ms = 5 seconds

            return () => {
                clearInterval(intervalId);
            };
        }
    }, []);

    const saveUser = async (user) => {
        try {
            const response = await fetch(`${API_URL}/api/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            });

            if (response.ok) {
                console.log('User saved successfully');
            } else {
                console.error('Failed to save user');
            }
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const fetchUserStats = async (userId) => {
        try {
            // Process mining on the backend
            const miningResponse = await fetch(`${API_URL}/api/users/mining`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: userId }),
            });

            if (!miningResponse.ok) {
                console.error('Failed to process mining:', miningResponse.statusText);
                return;
            }

            const miningData = await miningResponse.json();
            console.log('Mining processed:', miningData);

            // Fetch updated user stats
            const statsResponse = await fetch(`${API_URL}/api/users/${userId}`);
            if (!statsResponse.ok) {
                console.error('Failed to fetch user stats:', statsResponse.statusText);
                return;
            }

            const userData = await statsResponse.json();

            // Update frontend state with the latest user stats
            setSound(userData.sound);
            setVibration(userData.vibration);
            setLanguage(userData.language);
            setLevel(userData.level);
            setProgress(userData.progress_bar);
            setCoins(userData.coins); // Update coins
            setEnergy(userData.energy); // Update energy
            setIncomeTap(userData.per_tap);
            setIncomeHour(userData.mining_rate);
            setMaxEnergy(userData.energy_limit);
            console.log('User stats updated:', userData);
        } catch (error) {
            console.error('Error syncing user data:', error);
            setError('Error syncing user data');
        }
    };


    const fetchUserEnergy = async (userId) => {
        try {
            const response = await fetch(`${API_URL}/api/users/energy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: userId }),
            });

            if (!response.ok) {
                console.error('Failed to sync energy:', response.statusText);
                return;
            }

            const data = await response.json();
            console.log('Energy synced:', data);

            // Fetch updated user stats to reflect new energy value
            const statsResponse = await fetch(`${API_URL}/api/users/${userId}`);
            if (!statsResponse.ok) {
                console.error('Failed to fetch user stats after energy sync:', statsResponse.statusText);
                return;
            }

            const userData = await statsResponse.json();
            setEnergy(userData.energy); // Update energy state
        } catch (error) {
            console.error('Error syncing energy:', error);
            setError('Error syncing energy');
        }
    };


    const fetchTasksNum = async (id) => {
        try {
            const response = await fetch(`${API_URL}/api/tasks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: id }),
            });
            const data = await response.json();

            if (response.ok) {
                setTaskNum(data.length);
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };


    function formatNumber(num) {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B'; // Billions
        }
        if (num >= 1e6) {
            return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
        }
        if (num >= 1e3) {
            return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k'; // Thousands
        }
        return num.toString(); // Less than 1000
    }

    const spinFormatTime = (ms) => {
        const hours = String(Math.floor(ms / (1000 * 60 * 60))).padStart(2, '0');
        const minutes = String(Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        const seconds = String(Math.floor((ms % (1000 * 60)) / 1000)).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };


    const fetchSpinStatus = async (userId) => {
        try {
            const response = await fetch(`${API_URL}/api/spins-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: userId }),
            });

            if (!response.ok) {
                console.error('Failed to fetch spin status:', response.statusText);
                return;
            }

            const data = await response.json();
            console.log('Spin status fetched:', data);

            // Set the remaining time and spins directly from the response
            setRemainingTime(data.remainingTime);
            setSpins(data.spins);
        } catch (error) {
            console.error('Error fetching spin status:', error);
        }
    };



    function mapNumber(value, inMin, inMax, outMin, outMax) {
        if (value <= inMin) return outMin;
        if (value >= inMax) return outMax;

        return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    }

    const height = mapNumber(window.innerHeight, 600, 940, 10, 80);


    const MainStats = ({ coins, incomeHour, incomeTap }) => (
        <div className="main-stats text-center" style={{ marginTop: `${height}px` }}>
            <div className="container mt-2 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center">
                    <img src="https://web.mgc660.com/coin.svg" className="coin-icon me-2" alt="coin icon"></img>
                    <h2>{Math.floor(coins)}</h2> {/* Display dynamic coin count */}
                </div>
            </div>
            <div className="container mt-3">
                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center me-4">
                        <img className="me-2" src="https://web.mgc660.com/incomehour.svg" alt="income hour"></img>
                        <p className="income"><span>{formatNumber(incomeHour)}</span> {t('income_hour')}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <img className="me-2" src="https://web.mgc660.com/incometap.svg" alt="income tap"></img>
                        <p className="income"><span>{incomeTap}</span> {t('income_tap')}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const LuckySpin = ({ energy, maxEnergy }) => (
        <div className="lucky-spin container d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column h-100">
                <div className="energy d-flex align-items-center mt-auto">
                    <img className="me-2" src="https://web.mgc660.com/energy.svg" alt="energy icon"></img>
                    <p className="mb-0">{energy} <span>/</span> <span>{maxEnergy}</span></p>
                </div>
            </div>

            {remainingTime === 0 ? (
                <Link to="/spin" className="text-decoration-none text-white">
                    <div className="spinBox ms-auto">
                        <img src="https://web.mgc660.com/luckyspin.svg" alt="lucky spin" />
                        <p>{t('lucky_spin')}</p>
                        <p>{t('available')}</p>
                    </div>
                </Link>
            ) : (
                <div className="spinBox ms-auto">
                    <img src="https://web.mgc660.com/luckyspin.svg" alt="lucky spin" />
                    <p>{t('lucky_spin')}</p>
                    <p>{spinFormatTime(remainingTime)}</p>
                </div>
            )}

        </div>
    );

    /////////////////////


    const Avatar = () => {
        const [tapCount, setTapCount] = useState(0);
        const plusOneElements = useRef([]);
        const avatarRef = useRef(null);
        const lastTapTime = useRef(0); // Store the last tap timestamp
        const TAP_DELAY = 85; // Minimum delay (ms) between taps

        const vibrate = () => {
            if (tg.HapticFeedback && vibration) {
                tg.HapticFeedback.impactOccurred('light');
            } else {
                console.log('HapticFeedback API not available');
            }
        };

        const triggerAnimation = (taps) => {
            vibrate();
            setTapCount(taps.length);

            const avatarRect = avatarRef.current.getBoundingClientRect();

            taps.forEach((tap, index) => {
                if (!plusOneElements.current[index]) return;

                const x = tap.x - avatarRect.left;
                const y = tap.y - avatarRect.top;

                plusOneElements.current[index].style.left = `${x}px`;
                plusOneElements.current[index].style.top = `${y}px`;
                plusOneElements.current[index].classList.add("animate");

                setTimeout(() => {
                    if (plusOneElements.current[index]) {
                        plusOneElements.current[index].classList.remove("animate");
                    }
                }, 500);
            });
        };

        const handleLogic = async (tapCount) => {
            if (user && user.id) {
                try {
                    await fetch(`${API_URL}/api/tap`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ id: user.id, tapCount }),
                    });
                } catch (error) {
                    console.error('Error during tap request:', error);
                }
            }
        };

        const handleTouchStart = (e) => {
            e.preventDefault();

            const now = Date.now();
            if (now - lastTapTime.current < TAP_DELAY) return; // Ignore taps too close together
            lastTapTime.current = now;

            const taps = Array.from(e.touches).map(touch => ({
                x: touch.clientX,
                y: touch.clientY
            }));

            triggerAnimation(taps);
            handleLogic(taps.length);
        };

        return (
            <div
                ref={avatarRef}
                className="avatar text-center"
                style={{ marginTop: `${height}px`, position: "relative" }}
                onTouchStart={handleTouchStart}
                onClick={(e) => {
                    const now = Date.now();
                    if (now - lastTapTime.current < TAP_DELAY) return;
                    lastTapTime.current = now;

                    setTapCount(1);
                    triggerAnimation([{ x: e.clientX, y: e.clientY }]);
                    handleLogic(1);
                }}
            >
                <img src={`https://web.mgc660.com/tigers/lvl${level}.png`} alt="Avatar" className="tap-image" />
                {Array(5).fill().map((_, i) => (
                    <div key={i} ref={(el) => (plusOneElements.current[i] = el)} className="plus">
                        +{incomeTap}
                    </div>
                ))}
            </div>
        );
    };

    const Tasks = () => (
        <Link to="/Task" className={`tasks text-center d-flex align-items-center text-decoration-none text-white`} style={{ marginTop: `${height}px` }}>
            <div className='container d-flex align-items-center'>
                <img className="me-3" src="https://web.mgc660.com/tasks.svg"></img>
                <p className="me-3">{t('tasks')}
                </p>
                <div className="taskNumber">{taskNum}</div>
            </div>
        </Link>
    );

    const [isPopupVisible, setIsPopupVisible] = useState(true);

    const togglePopup = async () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const referralLink = `https://t.me/ftntest_bot?start=${user?.id || '1111111111'}`;
    const referralText = `🎁 50,000 OK.FUN Tokens are already waiting for you at OKFUN! 😌 I invite you to join the game.`;

    const url = `https://t.me/share/url?url=${referralLink}&text=${encodeURIComponent(referralText)}`;

    const handleInviteClick = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(url);
        } else {
            window.open(url, '_blank');
        }
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(referralLink) // Copy the referral link to the clipboard
            .then(() => alert(t('referral_link_copied'))) // Using the translation for the success message
            .catch(err => alert(t('failed_to_copy_referral_link') + err)); // Using translation for error message
    };

    const Home = () => (
        <div className="home">
            <div className="container d-flex flex-column align-items-center text-white">
                <Header language={language}
                    name={user?.first_name || 'Guest'}
                    level={level}
                    progress={progress}
                    photoUrl={user?.photo_url || 'default-profile.png'}
                />
                <MainStats coins={coins} incomeHour={incomeHour} incomeTap={incomeTap} />
                <Avatar /> {/* Pass handleAvatarClick as prop */}
                <LuckySpin energy={energy} maxEnergy={maxEnergy} />
                <Tasks />
                <Footer language={language} />
            </div>
            {isPopupVisible && (
                <>
                    <div className="overlay" onClick={togglePopup}></div>
                    <div className="popup text-center">
                        <img src="https://web.mgc660.com/close.svg" className='close' onClick={togglePopup}></img>
                        <div className='container d-flex flex-column'>
                            <img className="logo" src="https://web.mgc660.com/popupGift.svg"></img>
                            <h3>{t('send_a_gift_to_a_friend_and_earn_even_more')}</h3>
                            <p>{t('send_free_gifts_to_your_friends_and_start_earning_of_each_friends_profits')}</p>
                            <div className="d-flex align-items-center mt-2">
                                <div className='card me-3' onClick={handleInviteClick}>{t('invite_a_friend')}</div>
                                <div className='copy d-flex align-items-center justify-content-center' onClick={handleCopyClick}>
                                    <img src="https://web.mgc660.com/copy.svg"></img>
                                </div>
                            </div>
                            <p className='mt-3 mb-3'>{t('dont_forget_to_collect_the_daily_reward')}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/earn" element={<Earn language={language} id={user?.id || '1111111111'} coins={parseInt(coins)} incomeTap={incomeTap} energy={energy} maxEnergy={maxEnergy} />} />
                <Route path="/friends" element={<Friends language={language} id={user?.id || '1111111111'} name={user?.first_name || 'Guest'} level={level} progress={progress}
                    photoUrl={user?.photo_url || 'default-profile.png'} />} />
                <Route path="/airdrop" element={<Airdrop language={language} id={user?.id || '1111111111'} name={user?.first_name || 'Guest'} level={level} progress={progress} photoUrl={user?.photo_url || 'default-profile.png'} />} />
                <Route path="/profile" element={<Profile language={language} name={user?.first_name || 'Guest'} level={level} progress={progress} photoUrl={user?.photo_url || 'default-profile.png'} />} />
                <Route path="/settings" element={<Settings language={language} id={user?.id || '1111111111'} />} />
                <Route path="/task" element={<Task language={language} id={user?.id || '1111111111'} />} />
                <Route path="/spin" element={<Spin spins={spins} language={language} id={user?.id || '1111111111'} />} />
            </Routes>
        </Router>
    );
};

export default App;
