import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import translations from './utils/translations';
import { API_URL } from './utils/config';

const Task = ({ language, id }) => {
    const [tasks, setTasks] = useState([]);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [taskCompleted, setTaskCompleted] = useState(false);
    const t = (key) => translations[language][key] || key;

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            tg.BackButton.hide();
            window.history.back();
        });

        const fetchTasks = async () => {
            try {
                const response = await fetch(`${API_URL}/api/tasks`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ id: id }),
                });
                const data = await response.json();

                if (response.ok) {
                    setTasks(data);
                } else {
                    console.error(data.message);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();

        return () => {
            tg.BackButton.offClick();
        };
    }, [id]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && taskCompleted) {
                alert('Task completed successfully!');
                setTaskCompleted(false); // Reset the state
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [taskCompleted]);

    const togglePopup = (task) => {
        setSelectedTask(task);
        setIsPopupVisible(!isPopupVisible);
    };

    const handleTaskReward = async (taskId) => {
        try {
            const response = await fetch(`${API_URL}/api/task-reward`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: parseInt(id), taskId: parseInt(taskId) }),
            });

            const data = await response.json();

            if (response.ok) {
                setTaskCompleted(true); // Set the task as completed
                setTasks(tasks.filter(task => task.taskId !== taskId));
            } else {
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            alert("An error occurred. Please try again later.");
            console.error(error);
        }
    };

    const openURL = (url) => {
        try {
            if (url.startsWith("https://t.me/")) {
                try {
                    window.location.href = url;
                    return;
                } catch (error) {
                    console.warn("Failed to open tg:// link, falling back to https://t.me/");
                }
            }

            if (!/^https?:\/\/.+/.test(url)) {
                console.error("Invalid URL: Only 'http' and 'https' protocols are supported.");
                return;
            }

            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.openLink(url);
            } else {
                window.open(url, '_blank');
            }
        } catch (error) {
            console.error("Error opening the URL:", error);
        }
    };

    return (
        <div className="task">
            <div className='container'>
                <h2 className='ms-2 mt-4 text-start'>{t('social_networks')}</h2>
            </div>
            <div className='container d-flex flex-column justify-content-center align-items-center mt-3 mb-4 p-0'>
                <div className="allTasks p-0">
                    {tasks.map((task) => (
                        <>
                            <div key={task.taskId} className='container mt-3 mb-3 ms-2 d-flex align-items-center' onClick={() => togglePopup(task)}>
                                <img src="https://web.mgc660.com/taskIcon.svg" className='icon me-3' alt="Task Icon" />
                                <div>
                                    <h4>{task.title}</h4>
                                    <p className='mt-1'>
                                        <img src="https://web.mgc660.com/coin.svg" className='coinIcon me-1' alt="Coin Icon" />
                                        {task.coins.toLocaleString()}
                                    </p>
                                </div>
                                <img src="https://web.mgc660.com/rightarrow.svg" className='arrow ms-auto me-2' alt="Arrow Icon" />
                            </div>
                            <div className="container-fluid px-0">
                                <div className="full-width-line secondary"></div>
                            </div>
                        </>
                    ))}
                </div>
            </div>

            {isPopupVisible && selectedTask && (
                <>
                    <div className="overlay" onClick={() => setIsPopupVisible(false)}></div>
                    <div className="popup">
                        <img src="https://web.mgc660.com/close.svg" className='close' onClick={() => setIsPopupVisible(false)} alt="Close" />
                        <div className='container d-flex flex-column align-items-center'>
                            <img className="mt-5 logo" src="https://web.mgc660.com/coinLogo.png" alt="Coin Logo" />
                            <h3 className='p-4 text-center'>{selectedTask.title}</h3>
                            <h3 className="price mb-4 d-flex justify-content-center align-items-center">
                                <img src="https://web.mgc660.com/coin.svg" className='ms-2 me-2' alt="Coin Icon" />
                                {selectedTask.coins.toLocaleString()}
                            </h3>
                            <div onClick={() => { handleTaskReward(selectedTask.taskId); openURL(selectedTask.url); }} className="submit mt-4 mb-3 d-flex justify-content-center align-items-center">
                                {t('complete_task')}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Task;