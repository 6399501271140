import React, { useState, useEffect } from 'react';
import './Spin.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import translations from './utils/translations';
import { API_URL } from './utils/config';

const prizes = [
    { label: '10 coins' },
    { label: '20 coins' },
    { label: '100 coins' },
    { label: '1000 coins' },
    { label: '5000 coins' },
    { label: '2 Spins' },
    { label: 'Bad Luck' },
    { label: 'Jackpot (10,000 coins)' }
];

const Spin = ({ id, spins, language }) => {
    const t = (key) => translations[language][key] || key;

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            tg.BackButton.hide();
            window.history.back();
        });

        return () => {
            tg.BackButton.offClick();
        };
    }, []);

    const [rotation, setRotation] = useState(0);
    const [result, setResult] = useState(null);
    const [rewardMessage, setRewardMessage] = useState(null);
    const [remainingSpins, setRemainingSpins] = useState(null);
    const [totalCoins, setTotalCoins] = useState(null);
    const [spinning, setSpinning] = useState(false);

    const handleSpin = async () => {
        if (spinning) return;

        setSpinning(true);
        setResult(null);
        setRewardMessage(null);

        try {
            const response = await fetch(`${API_URL}/api/spin`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Spin failed');
            }

            const data = await response.json();
            const prizeIndex = prizes.findIndex(p =>
                p.label === data.result || (typeof data.result === 'number' && p.label.startsWith(data.result + ' coins'))
            );

            if (prizeIndex === -1) {
                console.error('Prize not found:', data.result);
                setResult('Error: Prize not found');
                setSpinning(false);
                return;
            }

            const segmentAngle = 360 / prizes.length;
            const targetAngle = prizeIndex * segmentAngle;
            const extraSpins = 5 * 360; // Ensure multiple full spins in the same direction
            const finalAngle = rotation + extraSpins + (360 - (rotation % 360) - targetAngle);

            setRotation(finalAngle);

            setTimeout(() => {
                setResult(prizes[prizeIndex].label);
                setRewardMessage(data.rewardMessage);
                setRemainingSpins(data.remainingSpins);
                setTotalCoins(data.totalCoins);
                setSpinning(false);
            }, 3000);
        } catch (error) {
            console.error('Error spinning:', error);
            setResult('Error: ' + error.message);
            setSpinning(false);
        }
    };

    return (
        <div className='games align-items-center'>
            <div className='title mt-4'>
                <h4>{t('free_spin_every_day')}</h4>
                <h1>{t('lucky_spin')}</h1>
            </div>
            <div className='spin-container mt-5'>
                <button id='spin' onClick={handleSpin} disabled={spinning}>
                    <span>{spinning ? t('spinning') : t('spin')}</span>
                    <br></br>
                    <span>{spinning ? '' : t('to_win')}</span>
                </button>
                <img src="https://web.mgc660.com/spinArrow.svg" className='spin-arrow' alt='Arrow'></img>
                <div>
                    <img
                        src='https://web.mgc660.com/wheel.svg'
                        alt='Spin Wheel'
                        className='wheel-image'
                        style={{ transform: `rotate(${rotation}deg)`, transition: spinning ? 'transform 3s ease-out' : 'none' }}
                    />
                </div>
            </div>
            <div className='results'>
                {rewardMessage && <div className='reward-message'>{rewardMessage}</div>}
                {result?.includes("coins") && (
                    <div className='reward-message'>
                        {t('your_prize_has_been_automatically_added_to_your_balance')} 💰✨
                    </div>
                )}
            </div>
            <p>{t('spins_left')} <span>{spins}</span></p>
            <div onClick={handleSpin} className='longSpinButton d-flex align-items-center justify-content-center'>{t('spin_to_win')}</div>
        </div>
    );
};

export default Spin;
