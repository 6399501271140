import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer.js';
import translations from './utils/translations';
import { API_URL } from './utils/config';


const Earn = ({ language, id, coins, incomeTap, energy, maxEnergy }) => {

    const [activeTab, setActiveTab] = useState("Mining");
    const [upgradeEnergyPrice, setUpgradeEnergyPrice] = useState(null);
    const [upgradeTapPrice, setUpgradeTapPrice] = useState(null);
    const [energyCardStatus, setEnergyCardStatus] = useState({
        dailyLimit: 5,
        usedToday: 0,
        canBuy: false,
        remainingCooldownTime: 0,
    });
    const [timeLeft, setTimeLeft] = useState("00:00:00");
    const [miningCards, setMiningCards] = useState([]);
    const [userMiningCards, setUserMiningCards] = useState([]);
    const [isMiningPopupVisible, setIsMiningPopupVisible] = useState(false);
    const [isEnergyPopupVisible, setIsEnergyPopupVisible] = useState(false);
    const [isLimitPopupVisible, setIsLimitPopupVisible] = useState(false);
    const [isTapPopupVisible, setIsTapPopupVisible] = useState(false);
    const [selectedMiningCard, setSelectedMiningCard] = useState(null);
    const [claimStatus, setClaimStatus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timer, setTimer] = useState('');
    const t = (key) => translations[language][key] || key;


    const fetchEnergyCardStatus = async () => {
        try {
            const response = await fetch(`https://ftn.iotlab.am/api/users/${id}/full-energy-card-status`);
            if (!response.ok) {
                throw new Error('Failed to fetch card status');
            }

            const data = await response.json();
            setEnergyCardStatus(data);

            if (data.remainingCooldownTime > 0) {
                startEnergyCountdown(data.remainingCooldownTime);
            }
        } catch (error) {
            console.error('Error fetching card status:', error);
        }
    };

    const startEnergyCountdown = (seconds) => {
        const interval = setInterval(() => {
            seconds -= 1;

            const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
            const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
            const secs = String(seconds % 60).padStart(2, '0');

            setTimeLeft(`${hours}:${minutes}:${secs}`);

            if (seconds <= 0) {
                clearInterval(interval);
                fetchEnergyCardStatus();
            }
        }, 1000);
    };

    const useEnergyCard = async () => {
        if (energy == maxEnergy) {
            alert("Energy is already full");
        }
        else {
            try {
                const response = await fetch(`https://ftn.iotlab.am/api/users/${id}/use-energy-card`, {
                    method: 'POST',
                });

                const data = await response.json();

                if (!response.ok) {
                    throw new Error(data.message || 'Failed to use energy card');
                }

                alert(data.message);
                fetchEnergyCardStatus();
            } catch (error) {
                alert(error.message);
            }
        }
    };

    const fetchEnergyUpgradePrice = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/energy-upgrade-price`);
            const data = await response.json();
            setUpgradeEnergyPrice(data.nextUpgradeCost);
        } catch (error) {
            console.error('Error fetching upgrade price', error);
        }
    };

    const fetchTapUpgradePrice = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/tap-upgrade-price`);
            const data = await response.json();
            setUpgradeTapPrice(data.nextUpgradeCost);
        } catch (error) {
            console.error('Error fetching upgrade price', error);
        }
    };

    const handleEnergyPurchase = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/buy-energy-upgrade`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id }),
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message);
                fetchEnergyUpgradePrice();
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert('Error during purchase: ' + error.message);
        }
    };

    const handleTapPurchase = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/buy-tap-upgrade`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id }),
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message);
                fetchTapUpgradePrice();
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert('Error during purchase: ' + error.message);
        }
    };

    const fetchMiningCards = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/mining-cards`);
            const data = await response.json();
            setMiningCards(data);
        } catch (error) {
            console.error("Error fetching mining miningCards:", error);
        }
    };

    const fetchUserMiningCards = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/mining-cards-status`);
            const data = await response.json();
            setUserMiningCards(data.miningCards);
        } catch (error) {
            console.error("Error fetching user mining miningCards:", error);
        }
    };

    const buyMiningCard = async (cardId) => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/buy-mining-card`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ cardId }),
            });

            const data = await response.json();

            if (response.ok) {
                alert("Card purchased successfully!");
                console.log("Card purchased successfully:", data);
                toggleMiningPopup();

                await fetchUserMiningCards();
            } else {
                alert(`Error purchasing card: ${data.message || "Unknown error"}`);
                console.error("Error purchasing card:", data.message || "Unknown error");
            }
        } catch (error) {
            alert("Network error occurred while purchasing the card. Please try again.");
            console.error("Network error purchasing card:", error);
        }
    };

    const toggleMiningPopup = (card) => {
        setSelectedMiningCard(card || null);
        setIsMiningPopupVisible(!isMiningPopupVisible);
    };

    const toggleEnergyPopup = () => {
        setIsEnergyPopupVisible(!isEnergyPopupVisible);
    };

    const toggleLimitPopup = () => {
        setIsLimitPopupVisible(!isLimitPopupVisible);
    };

    const toggleTapPopup = () => {
        setIsTapPopupVisible(!isTapPopupVisible);
    };

    const fetchDailyCoinsStatus = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/daily-coins-status`);
            const data = await response.json();
            if (data.status === 'success') {
                setClaimStatus(data.days);
                if (!data.claimAvailable) {
                    startDailyClaimTimer(data.timeRemaining);
                }
            }
        } catch (error) {
            console.error("Error fetching claim status:", error);
        } finally {
            setLoading(false);
        }
    };

    const claimDailyCoins = async (day) => {
        try {
            const response = await fetch(`${API_URL}/api/users/${id}/claim-daily-coins`, {
                method: 'POST',
            });
            const data = await response.json();
            if (data.status === 'success') {
                setClaimStatus((prevState) => {
                    const updatedDays = prevState.map(d =>
                        d.day === day ? { ...d, claimed: true, available: false } : d
                    );
                    return updatedDays;
                });
                fetchDailyCoinsStatus();
            }
        } catch (error) {
            console.error("Error claiming coins:", error);
        }
    };

    const startDailyClaimTimer = (timeRemaining) => {
        const interval = setInterval(() => {
            let hours = Math.floor(timeRemaining / (1000 * 60 * 60));
            let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            setTimer(`${hours}:${minutes}:${seconds}`);
            timeRemaining -= 1000;
            if (timeRemaining <= 0) {
                clearInterval(interval);
                setTimer('');
            }
        }, 1000);
    };

    const getClaimButtonText = () => {
        const availableDay = claimStatus.find(day => day.available);
        if (availableDay) {
            return t('claim');
        }
        return timer || t('claim');
    };

    const isClaimAvailable = () => {
        return claimStatus.some(day => day.available);
    };

    function formatNumber(num) {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B'; // Billions
        }
        if (num >= 1e6) {
            return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
        }
        if (num >= 1e3) {
            return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k'; // Thousands
        }
        return num.toString(); // Less than 1000
    }

    useEffect(() => {
        fetchEnergyCardStatus();
        fetchEnergyUpgradePrice();
        fetchTapUpgradePrice();
        fetchMiningCards();
        fetchUserMiningCards();
        fetchDailyCoinsStatus();
    }, []);

    return (
        <>
            <div className="earn">
                <div className="container d-flex flex-column align-items-center text-white">
                    <div className="container mt-3 m-0">
                        <div className="row text-center m-0 text-nowrap">
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <div className="content d-flex align-items-center">
                                    <img className="me-2" src='https://web.mgc660.com/coin.svg' alt="Coin Icon"></img>
                                    <div>
                                        <h6>{t('account')}</h6>
                                        <p>{formatNumber(coins)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 d-flex align-items-center">
                                <div className="vertical-line mx-auto"></div>
                            </div>

                            <div className="col-2 d-flex justify-content-center align-items-center">
                                <div className="content d-flex align-items-center">
                                    <img className="me-2" src='https://web.mgc660.com/energy.svg' alt="Energy Icon"></img>
                                    <div>
                                        <h6>{t('energy_limit')}</h6>
                                        <p>{maxEnergy}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-2 d-flex align-items-center">
                                <div className="vertical-line mx-auto"></div>
                            </div>

                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <div className="content d-flex align-items-center">
                                    <img className="me-2" src='https://web.mgc660.com/incometap.svg' alt="Income Tap Icon"></img>
                                    <div>
                                        <h6>{t('income_tap')}</h6>
                                        <p>{incomeTap}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="nav-tab mt-4 text-white">
                        <button
                            className={activeTab === "Mining" ? "active" : ""}
                            onClick={() => setActiveTab("Mining")}
                        >
                            {t('mining')}
                        </button>
                        <button
                            className={activeTab === "Daily" ? "active" : ""}
                            onClick={() => setActiveTab("Daily")}
                        >
                            {t('daily')}
                        </button>
                        <button
                            className={activeTab === "Boosters" ? "active" : ""}
                            onClick={() => setActiveTab("Boosters")}
                        >
                            {t('boosters')}
                        </button>
                    </div>

                    {activeTab === "Mining" && (
                        <div className="container">
                            <div className="mining-miningCards">
                                {miningCards.map((card) => {
                                    const userCard = userMiningCards.find((uc) => uc.cardId === card.id);

                                    const mergedCard = {
                                        ...card,
                                        lvl: userCard ? userCard.level : 0,
                                        currentPrice: userCard ? userCard.price : card.price,
                                        currentMiningRateIncrement: userCard
                                            ? userCard.miningRateIncrement
                                            : card.mining_rate_increment,
                                    };

                                    return (
                                        <div key={mergedCard.id} className="d-flex align-items-center justify-content-center mt-3">
                                            <div className="card container m-0 d-flex justify-content-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="iconBox d-flex align-items-center me-2">
                                                        <img src={`https://web.mgc660.com/miningIcons/${mergedCard.icon_url}`} className="miningIcon" alt="Icon"></img>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center me-2">
                                                        <h4>{mergedCard.title}</h4>
                                                        <div className="d-flex align-items-center">
                                                            <p className="level me-2">{mergedCard.lvl} {t('level')}</p>
                                                            <p className="increment">
                                                                <img src="https://web.mgc660.com/dollar.svg" className="me-1"></img>+{mergedCard.currentMiningRateIncrement}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        onClick={() => toggleMiningPopup(mergedCard)}
                                                        className="d-flex infoCard active ms-auto align-items-center justify-content-center"
                                                    >
                                                        <img className="me-1" src="https://web.mgc660.com/coin.svg" alt="Coin Icon"></img>
                                                        <span>{mergedCard.currentPrice}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                {isMiningPopupVisible && selectedMiningCard && (
                                    <>
                                        <div className="overlay" onClick={() => toggleMiningPopup()}></div>
                                        <div className="popup">
                                            <div className="container d-flex flex-column align-items-center">
                                                <img src={`https://web.mgc660.com/miningIcons/${selectedMiningCard.icon_url}`} className="miningIcon" alt={`${selectedMiningCard.title} Icon`}></img>
                                                <h3>{selectedMiningCard.title}</h3>
                                                <p className="secondary">{selectedMiningCard.description}</p>
                                                <div className="progress-container">
                                                    {[...Array(10)].map((_, index) => (
                                                        <div key={index} className={`progress-point ${index < selectedMiningCard.lvl ? 'active' : ''}`}></div>
                                                    ))}
                                                </div>
                                                <div className="card big justify-content-center align-items-center mt-4">
                                                    <p className="price">{selectedMiningCard.lvl} {t('level')}</p>
                                                    <p className="secondary mt-1">{t('income_hour')}</p>
                                                    <p>
                                                        <img src="https://web.mgc660.com/coin.svg" className="me-1"></img>
                                                        {selectedMiningCard.currentMiningRateIncrement}
                                                    </p>
                                                </div>
                                                <div onClick={() => buyMiningCard(selectedMiningCard.id)} className="submit mt-4 mb-3 d-flex justify-content-center align-items-center">
                                                    {t('upgrade')}
                                                    <img src="https://web.mgc660.com/coin.svg" className="ms-2 me-2"></img>
                                                    {selectedMiningCard.currentPrice}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {activeTab === "Boosters" && (
                        <div className="container">
                            <div className='container text-center mt-3'>
                                <h2>{t('buy_upgrades_and_increase_your_income')}</h2>
                            </div>
                            <h3 className="mt-4">{t('free_daily_power_ups')}
                            </h3>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                                <div className="card container m-0 d-flex justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="iconBox d-flex align-items-center me-2">
                                            <img className="battery" src="https://web.mgc660.com/greenbattery.svg" alt="Icon"></img>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center me-2">
                                            <h4>{t('full_energy')}
                                            </h4>
                                            <div className="d-flex align-items-center">
                                                <p className="count me-2">{energyCardStatus.dailyLimit - energyCardStatus.usedToday} / {energyCardStatus.dailyLimit}</p>
                                                <p className="status">{t('available')}
                                                </p>
                                            </div>
                                        </div>
                                        <div onClick={energyCardStatus.canBuy ? toggleEnergyPopup : null} className={`d-flex ${energyCardStatus.canBuy ? "active" : ""} infoCard ms-auto align-items-center justify-content-center`}>
                                            {energyCardStatus.canBuy ? t('get_free')
                                                : ((energyCardStatus.dailyLimit == energyCardStatus.usedToday) ? "00:00:00" : timeLeft)}
                                        </div>
                                        {isEnergyPopupVisible && (
                                            <>
                                                <div className="overlay" onClick={toggleEnergyPopup}></div>
                                                <div className="popup">
                                                    <div className='container d-flex flex-column align-items-center'>
                                                        <img src="https://web.mgc660.com/bigBattery.svg"></img>
                                                        <h3>{t('full_energy')}
                                                        </h3>
                                                        <p>{t('recharge_your_energy_to_the_maximum_and_do_another_round_of_mining')}
                                                        </p>
                                                        <div className="card justify-content-center align-items-center mt-4">
                                                            <div className='d-flex'>
                                                                <p className='me-2'>{t('Avalable')}</p>
                                                                <p className="count me-2">{energyCardStatus.dailyLimit - energyCardStatus.usedToday} {t('out_of')} {energyCardStatus.dailyLimit}</p>
                                                            </div>
                                                        </div>
                                                        <div onClick={useEnergyCard} className="submit mt-4 mb-3 d-flex justify-content-center align-items-center">{t("get_free")}</div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <h3 className="mt-4">{t('power_ups')}
                            </h3>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                                <div className="card container m-0 d-flex justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="iconBox d-flex align-items-center me-2">
                                            <img className="battery" src="https://web.mgc660.com/greenbattery.svg" alt="Icon"></img>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center me-2">
                                            <h4>{t('energy_limit')}</h4>
                                            <div className="d-flex align-items-center">
                                                <p className="level me-2">{Math.log2(upgradeEnergyPrice / 2000)} {t('level')}</p>
                                                <img src="https://web.mgc660.com/energy.svg" className="energyIcon me-2" alt="Energy"></img>
                                                <p>+500</p>
                                            </div>
                                        </div>
                                        <div onClick={toggleLimitPopup} className="d-flex infoCard active ms-auto align-items-center justify-content-center">
                                            <img className="me-1" src="https://web.mgc660.com/coin.svg" alt="Coin Icon"></img>
                                            <span>{upgradeEnergyPrice !== null ? upgradeEnergyPrice.toLocaleString() : t('loading')}</span>
                                        </div>
                                        {isLimitPopupVisible && (
                                            <>
                                                <div className="overlay" onClick={toggleLimitPopup}></div>
                                                <div className="popup">
                                                    <div className='container d-flex flex-column align-items-center'>
                                                        <img src="https://web.mgc660.com/bigBattery.svg"></img>
                                                        <h3>{t('energy_limit')}</h3>
                                                        <p>{t('increase_your_energy_limit_by_using_this_card')}
                                                        </p>
                                                        <div className="card justify-content-center align-items-center mt-4">
                                                            <div className='d-flex align-items-center'>
                                                                <p className="level me-2">{Math.log2(upgradeEnergyPrice / 2000)} {t('level')}</p>
                                                                <img src="https://web.mgc660.com/energy.svg" className="energyIcon me-2" alt="Energy"></img>
                                                                <p>+500</p>
                                                            </div>
                                                        </div>
                                                        <div onClick={handleEnergyPurchase} className="submit mt-4 mb-3 d-flex justify-content-center align-items-center">
                                                            <img className="me-1" src="https://web.mgc660.com/coin.svg" alt="Coin Icon"></img>
                                                            <span>{upgradeEnergyPrice !== null ? upgradeEnergyPrice.toLocaleString() : t('loading')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                                <div className="card container m-0 d-flex justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="iconBox d-flex align-items-center me-2">
                                            <img className="battery" src="https://web.mgc660.com/multitap.svg" alt="Icon"></img>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center me-2">
                                            <h4>{t('income_per_tap')}
                                            </h4>
                                            <div className="d-flex align-items-center">
                                                <p className="level me-2">{Math.log2(upgradeTapPrice / 2000)} {t('level')}</p>
                                                <img src="https://web.mgc660.com/incometap.svg" className="energyIcon me-2" alt="IncomeTap"></img>
                                                <p>+1</p>
                                            </div>
                                        </div>
                                        <div onClick={toggleTapPopup} className="d-flex infoCard active ms-auto align-items-center justify-content-center">
                                            <img className="me-1" src="https://web.mgc660.com/coin.svg" alt="Coin Icon"></img>
                                            <span>{upgradeTapPrice !== null ? upgradeTapPrice.toLocaleString() : t('loading')}</span>
                                        </div>
                                        {isTapPopupVisible && (
                                            <>
                                                <div className="overlay" onClick={toggleTapPopup}></div>
                                                <div className="popup">
                                                    <div className='container d-flex flex-column align-items-center'>
                                                        <img src="https://web.mgc660.com/bigmultitap.svg"></img>
                                                        <h3>{t('income_per_tap')}</h3>
                                                        <p>{t('increase_your_income_per_tap_by_using_this_card')}
                                                        </p>
                                                        <div className="card justify-content-center align-items-center mt-4">
                                                            <div className='d-flex align-items-center'>
                                                                <p className="level me-2">{Math.log2(upgradeTapPrice / 2000)} {t('level')}</p>
                                                                <img src="https://web.mgc660.com/incometap.svg" className="energyIcon me-2" alt="IncomeTap"></img>
                                                                <p>+1</p>
                                                            </div>
                                                        </div>
                                                        <div onClick={handleTapPurchase} className="submit mt-4 mb-3 d-flex justify-content-center align-items-center">
                                                            <img className="me-1" src="https://web.mgc660.com/coin.svg" alt="Coin Icon"></img>
                                                            <span>{upgradeTapPrice !== null ? upgradeTapPrice.toLocaleString() : t('loading')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === "Daily" && (
                        <div className="container">
                            <div className='container p-0 text-center mt-3'>
                                <h2>{t('log_in_every_day_and_claim_your_bonus')}
                                </h2>
                            </div>
                            <div className="container p-0 d-flex flex-column align-items-center text-white mt-4">
                                <div className="container p-0">
                                    <div className="row g-2 mt-1">
                                        {loading ? (
                                            <div>{t('loading')}</div>
                                        ) : (
                                            claimStatus.map((dayStatus) => (
                                                <div className="col-3" key={dayStatus.day}>
                                                    <div onClick={() => claimDailyCoins(dayStatus.day)} className={`card daily ${dayStatus.available ? 'taken' : (dayStatus.claimed ? 'taken' : '')}`}>
                                                        {dayStatus.claimed && <img src="https://web.mgc660.com/doneIcon.svg" className="done" alt="done" />}
                                                        <div className="d-flex p-0 flex-column card-body align-items-center justify-content-center">
                                                            <p className="daysCount mb-2">{t('day')} {dayStatus.day}</p>
                                                            <img src="https://web.mgc660.com/coin.svg" className='mb-2' alt="coin" />
                                                            <p className='price'>1k</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="container p-0 d-flex justify-content-center align-items-center mt-4">
                                <div
                                    className={`timer d-flex justify-content-center align-items-center ${isClaimAvailable() ? 'active' : ''}`}
                                    onClick={() => isClaimAvailable() && claimDailyCoins(claimStatus.find(day => day.available).day)}
                                    disabled={!isClaimAvailable()}
                                >
                                    {getClaimButtonText()}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer language={language} />
        </>
    );
};

export default Earn;
